@import "scss/mixins/colors";

.ContactForm {
  .ContactForm-text-input {
    width: 300px !important;
  }

  .ContactForm-flex-container {
    display: flex;
    flex-direction: column;
  }

  .ContactForm-SameAsLocation {
    cursor: pointer;
    background-color: transparent;
    border: none;
    color: $recon-primary;
    outline: none;

    &:hover {
      color: $recon-primary;
    }
  }

  .ContactForm-select {
    width: 300px !important;
    margin-top: 8px !important;
    margin-bottom: 4px !important;
  }
}
