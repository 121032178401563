.CollectionHeaderBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  border-bottom: 1px solid rgb(230, 231, 232);
  background-color: rgb(251, 251, 251);

  .CollectionHeaderBar-text {
    white-space: nowrap;
  }

  .CollectionHeaderBar-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-left: 8px;
  }

  .MuiOutlinedInput-inputMarginDense {
    margin: 1px 4px !important;
    padding: 4px 8px !important;
    width: 135px !important;
  }

  .CollectionHeaderBar-edit-icon {
    font-size: 1.2rem !important;
  }

  .CollectionHeaderBar-edit-icon-container {
    margin-left: 4px;
  }
}