.UserOrganizationsDialog {
  z-index: 1500 !important;

  .MuiPaper-root {
    width: 500px !important;
    height: 540px !important;
  }

  .Admin_Table > div {
    height: 355px !important;
  }
}
