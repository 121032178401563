@import "scss/mixins/index";

.Header {
  position: relative;
  background-color: $recon-background-secondary;
  height: $header-height;
  z-index: $header-z-index;

  .Header-Navbar {
    height: $header-height;
    padding: 4px 16px;
    text-align: center;

    .Tabs {
      border-bottom: 0;
    }

    .img-fluid {
      padding-top: 6px;
      max-height: $header-img-height;
    }

    .navbar-brand {
      position: absolute;
      left: 10px;
      top: 7px;
    }

    .navbar-nav {
      font-size: 16px;
      line-height: 30px;

      .nav-item {
        display: inline-block;
        margin-bottom: 0;
      }
      .nav-link {
        display: inline-block;
        border-bottom: 0px unset;
        padding: 10px 26px 16px;
        margin: 0;
        height: 100%;
        color: $recon-white;
        text-decoration: none;
      }
      .nav-link:hover {
        background-color: rgba(251,251,251, 0.19);
        border-bottom: 0px unset;
        margin: 0;
      }
      .nav-link.active {
        padding-bottom: 10px;
        border-bottom: 6px solid $recon-tab-highlight;
        margin: 0;
      }
    }
  }

  .Header-logout {
    position: absolute;
    cursor: pointer;
    top: 20px;
    right: 25px;
    font-weight: bold;
    background-color: transparent;
    border: none;
    color: #f4f4f4;
    outline: none;
  }

  .Tabs-Container {
    text-align: center;
    margin-top: 0 !important;
    font-size: 16px;
    line-height: 30px;
  }

  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTab-textColorInherit {
    opacity: 1 !important;
  }
}
