.TagsList {
  margin-top: 8px;

  &-list {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-size: 17px;
    justify-content: flex-start;
  }

  &-empty {
    background-color: #fff;
    border: none;
    color: rgba(38, 50, 56, 0.48);
    cursor: pointer;
    font-size: 12px;
    font-weight: 600;
    max-height: 32px;
    min-width: 75px;
    padding-left: 0;
    text-transform: capitalize;
  }

  .MuiIconButton-root {
    padding: 4px;
    margin-top: 10px;
  }

  .MuiSvgIcon-root {
    border: none;
    font-size: 20px;
    color: rgba(38, 50, 56, 0.48);
    background-color: unset;
  }
}
