@import "../../../../../../scss/mixins/colors.scss";
.button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.contentContainer {
  padding: 10px;
  h2 {
    margin-bottom: 0;
  }
  .container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .contentSetting-wrapper {
      width: 25%;
    }

    .priceSettings-wrapper {
      width: 25%;
      form {
        display: flex;
        flex-direction: column;
        div.MuiTextField-root {
          margin: 10px 0;
        }
      }
    }
  }
}
.cancel {
  margin-left: auto;
}

.customPriceTextField {
  p {
    padding: 0;
    margin-top: 3px !important;
    color: #ccc !important;
  }
}

.MuiInputLabel-formControl {
  background-color: $recon-white;
  padding: 0 10px !important;
}

.MuiSelect-outlined.MuiSelect-outlined {
  background-color: $recon-white !important;
  padding: 10.5px 32px 10.5px 10.5px;
}

.communication-wrapper {
  width: 50%;
  form {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    div.MuiTextField-root {
      margin: 10px 0;
    }
  }
}

.opcode-exclude-wrapper {
  width: 50%;
  .input-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    div.MuiTextField-root {
      margin: 10px 0;
    }
  }
  padding-bottom: 20px;
}
