.EditTaskTemplateDialog {
  .EditTaskTemplateDialog__column1 {
    width: 500px;


  }

  .EditTaskTemplateDialog__column2 {
    .form-label {
      width: 85px;
    }
  }
}
