@import "scss/mixins/index";

.Tabs-Container {
  text-align: center;
  margin-top: 0 !important;
  font-size: 16px;
  line-height: 30px;

  .MuiTabs-flexContainer {
    justify-content: center;
  }

  .MuiTab-textColorInherit {
    opacity: 1 !important;
  }

  .header-tab-buttons {
    display: inline-block;
    border-bottom: 0px unset;
    padding: 10px 26px 16px;
    margin: 0;
    height: 100%;
    color: $recon-white !important;
    text-decoration: none;
    text-align: center;
    font-size: 16px;
    line-height: 30px;
    font-weight: 600;
  }

  .subheader-tab-buttons {
    display: inline-block;
    border-bottom: 0px unset;
    margin: 0;
    height: 100%;
    color: black !important;
    font-size: 14px !important;
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    line-height: 30px;
    min-width: 100px !important;

    .navbar-nav {
      padding: 0 0 6px 0 !important;
    }
  }

  .PrivateTabIndicator-root-1 {
    height: 5px !important;
    background-color: #ff0000 !important;
  }
}

.header-container {
  .PrivateTabIndicator-root-1 {
    background-color: #C60000 !important;
  }
}

.subheader-container {
  border-bottom: 1px solid #ddd !important;
}
