.TagChip {
  align-items: center;
  background-color: lightgray;
  border-radius: 6px;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: 10px;
  padding: 4px;
  white-space: nowrap;

  &:first-child {
    margin-left: 0px;
  }

  .MuiSvgIcon-root {
    color: rgba(38, 50, 56, 0.38);
    font-size: 16px;
  }
}
