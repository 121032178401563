@import "scss/mixins/layout";
@import "scss/mixins/colors";

.OrganizationDialog {
  .MuiFormHelperText-root {
    margin-top: 0px !important;
  }

  .form-label {
    width: auto !important;
  }

  .OrganizationDialog-text-input {
    width: 300px !important;

    &-name {
      margin-left: 50px !important;
    }

    &-large-margin {
      margin-left: 75px !important;
    }
  }

  .OrganizationDialog-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    &-label {
      margin-right: 12px !important;
      margin-left: 6px !important;
    }
  }

  .OrganizationDialog-select {
    width: 300px !important;
    margin-left: 41px !important;
    margin-top: 8px !important;
    margin-bottom: 4px !important;
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(14px, 13px) scale(1);
    pointer-events: none;
  }

  .OrganizationDialog-parent-select {
    margin-left: 50px !important;
  }

  .OrganizationDialog-time-zone-select {
    margin-left: 75px !important;
  }

  .OrganizationDialog-timezone-select-container {
    margin-left: -8px;
    margin-top: 8px;
  }
}

.OrganizationDialog-flex-container {
  display: flex;
  flex-direction: column;
}

