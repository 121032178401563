@import "scss/mixins/index";

.Settings-container {
	border-right: 1px solid $recon-grey-border;
	height: 100%;
}

.Settings-header {
	background-color: $recon-background-tertiary;
	color: $text-color;
	border-bottom: 1px solid $recon-dropdown-arrow-color;
	text-decoration: none;
}

.Settings-row {
	display: flex;
	align-items: center;
	color: $text-color;
	width: 100%;
}

.FeatureFlagRow-description {
	flex-wrap: wrap;
}

.Settings-title {
	font-weight: 600;
	font-size: 20px;
	border-bottom: 1px solid $recon-grey-border;
	justify-content: space-between;
	padding: 5px;
}

.Settings-row-border {
	border: 1px solid $recon-grey-border;
	border-top: 0;
}

.Settings-selectable {
	cursor: pointer;
	border-bottom: 1px solid $recon-grey-border;
	padding: 5px;
}

.Settings-icon-container {
	font-size: 48pt;
	line-height: 30pt;
}

.Settings-small {
	flex: 1 0 35px;
}

.Settings-selected {
	font-weight: bolder;
	background-color: $recon-dark-grey;
}

.Settings-disabled {
	background-color: $recon-disabled;
	padding: 5px;
	opacity: 0.5;
}
